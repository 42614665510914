
























































import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "Students",
  data: () => ({
    //students: [1, 2, 3]
  }),
  components: {},
  computed: {
    ...mapState(["students"]),
  },
  mounted() {
    this.$store.dispatch("loadStudents");
  },
});
